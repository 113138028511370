import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "./breadcrumbs";
import { pathnameIncludes } from "app/utils/utils";
import store from "core/state/redux/store";
import { trim, uniqBy } from "lodash";
const BreadcrumbsStateContainer = (props) => {
  // #ts-ignore
  const categoryList = store?.getState()?.queries?.data?.["2"]?.data?.categoryList;
  const location = useLocation();
  const isRegularProduct = useMemo(
    () =>
      location.pathname?.split("/")?.filter((el) => el)?.length > 1 &&
      !location.pathname.includes("build-your-own/"),
    [location.pathname]
  );
  function findCategoryById(categories, id) {
    if (isRegularProduct)
      return categories?.find((category) =>
        category.url_path?.includes(location.pathname)
      );
    if(!categories) return null;
    for (let category of categories) {
      if (category.id === id) return category;

      if (category.children && category.children.length > 0) {
        let result = findCategoryById(category.children, id);
        if (result) return result;
      }
    }
    return null;
  }

  const ModifiedLinks = findCategoryById(
    categoryList,
    (props?.product && props.product?.at(-2).id) || props.product
      ? props.product?.[props.product?.length - 1]?.id
      : 0
  );

  const isFromSearchPage = location?.search?.includes("search=true");

  const sacntify = (string) => {
    if (props.sku) {
      string = string.replace("-" + props.sku.toLowerCase(), "");
    }
    string = string.replace(/-/g, " ");
    string = string.replace("XXXX", "_ _ _ _-");
    if (window.location.pathname.includes("benchmade-benches"))
      string = string.replace("X XX", " _ - _ _");
    if (window.location.pathname.includes("benchmade-square"))
      string = string.replace("XXX XX", "_ _ _ - _ _");
    return string;
  };
  const getParents = (items, current) => {
    let currentIndex = items.indexOf(current);
    let urlPath = items.slice(0, currentIndex + 1).join("/");

    if (urlPath.indexOf("XXXX") > 0) {
      urlPath = urlPath.replace("/collections", "");
    }
    if (urlPath === "/customer") {
      urlPath = "/customer/account";
      current = "my-account";
    } else if (urlPath === "/customer/collections/lists") {
      current = "lists";
    } else if (urlPath === "/customer/collections") {
      //FIXME: This leads to | | should be fixed when we have time. -> this used to lead to the container collection page, we disabled it, however this is wrong logic and will need fixing.
      current = "";
    }

    return {
      url: urlPath.toLowerCase(),
      title: sacntify(current),
    };
  };

  const newLinksArray = () => {
    let newLinks = [];
    let items = ModifiedLinks.url_path.split("/");
    items.forEach((item, i) => {
      if (item === "") {
        newLinks.push({ url: "/", title: "home" });
      } else {
        newLinks.push(getParents(items, item));
      }
    });
    newLinks.push({
      url:
        newLinks[newLinks.length - 1].url +
        "/" +
        props.product
          ?.at(-1)
          .name.toLowerCase()
          .split(" ")
          .join("-")
          .replace(/-&-/, "-"),
      title: props.product?.at(-1).name.toLowerCase().replace(/&/, ""),
    });
    return newLinks;
  };

  const getLinks = () => {
    let links = [];
    let pathname = location.pathname;

    if (pathname.charAt(pathname.length - 1) === "/") {
      pathname = pathname.substr(0, pathname.length - 1);
    }

    pathname = pathname.replace("build-your-own", "collections/build-your-own");
    pathname = pathname.replace("collections/collections", "collections");

    let items = pathname.split("/");
    items.forEach((item, i) => {
      if (item === "") {
        links.push({ url: "/", title: "home" });
      } else {
        links.push(getParents(items, item));
      }
    });
    return links;
  };

  let linksArray = getLinks();

  return (
    <Breadcrumbs
      {...props}
      backupLinks={
        ModifiedLinks && !isFromSearchPage
          ? uniqBy(
              [...newLinksArray(), linksArray.at(-1)].map((item) => ({
                ...item,
                title: trim(item.title).replace(/\s+/g, " "),
              })),
              "title"
            )?.filter(
              (link) =>
                ModifiedLinks?.url_path?.includes(link?.url) ||
                link?.url?.split("/")?.filter((el) => el)?.length === 1
            )
          : getLinks()
      }
      pathnameIncludes={pathnameIncludes}
    />
  );
};

export default BreadcrumbsStateContainer;
